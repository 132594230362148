.navbarStyles {
  background-color: rgb(117, 121, 117) !important;
}

.headerStyles {
  border-bottom: 1px #222222 solid !important;
  box-shadow: 0 2px 5px #222222 !important;
}

.adminIcon {
  font-size: 1.3rem !important;
  color: #020202;
}

.keyIcon{
  list-style-type: none;
}
