.fileInput {
  color: white;
}
.imagePreview {
  width: 30rem;
  height: auto;
  margin: 2rem 0 1rem 0;
}

#addText{
    font-family: Alika;
    font-size: 50px !important;
    color: black;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
}

.add{
  font-family: Roboto !important;
  color: black !important;
}

