#frontpageContainer {
  padding-top: unset !important;
}

#frontDiv {
  background-image: url('../../assets/images/trees1.png');
  padding-bottom: 5%;
  padding-left: 10%;
}

#frontDiv h1 {
  padding-top: 10%;
  font-family: Alika;
  font-size: 4rem !important;
}

#frontDiv p {
  font-family: Roboto;
  font-size: 1.7rem;
}

.treeRow {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.treeCol {
  margin: 3% 0;
}

.cardFrontpage {
  height: max-content;
  transition: transform 0.2s;
  border: none !important;
  box-shadow: 2px 2px 8px #222222;
}

/* .cardFrontpage {
  background-color: var(--card-background-color) !important;
  padding: 2%;
  max-width: 320px !important;

  border-radius: 10px !important;
  margin-left: auto;
  margin-right: auto;
} */

.cardImageFrontpage {
  border-radius: 10px !important;
  /* width: 100%; */
}
.slideShowImage {
  border-radius: 10px !important;
  width: 100%;
  height: 22rem;
  object-fit: cover;
}
.slideShowLink {
  display: flex;
  justify-content: center;
}

.cardBtn {
  margin-top: 10%;
  padding: 3% 6% !important;
  border-radius: 10px !important;
  background-color: rgb(212, 212, 212) !important;
  color: black !important;
  font-size: 26px !important;
}

.carouselContainer {
  padding: 5%;
  display: none;
  /* border: 1px solid black; */
}
.carousel-inner {
  width: 22rem !important;
}

@media only screen and (max-width: 993px) {
  .carouselContainer {
    display: unset;
  }
  .treeCol {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .highlightText {
    font-size: 20px !important;
  }
}

@media only screen and (min-width: 768px) {
  .cardImageFrontpage {
    height: 13rem !important;
    width: 13rem !important;
    object-fit: cover;
  }
}
@media only screen and (min-width: 1200px) {
  .cardImageFrontpage {
    height: 15rem !important;
    width: 15rem !important;
    object-fit: cover;
  }
}

.udvalgteProdukterH1 {
  font-family: Alika !important;
  font-size: 50px !important;
  margin-top: 5% !important;
  color: black;
  background-color: #eee;
  border-radius: 10px;
  padding: 1%;
}

.btnSeeMore {
  background-color: #757e74 !important;
  box-shadow: 2px 2px 4px #222222;
  margin-bottom: 40% !important;
  font-size: 20px !important;
  font-family: Roboto;
  font-weight: 800 !important;
  font-size: 30px !important;
  color: black !important;
}

@media only screen and (max-width: 768px) {
  #frontDiv {
    background-image: url('../../assets/images/trees1.png');
    padding-bottom: 5%;
    padding-left: 10%;
    background-size: cover !important;
  }

  #frontDiv h1 {
    padding-top: 10%;
    font-family: Alika;
    font-size: 2.5rem !important;
  }

  #frontDiv p {
    font-family: Roboto;
    font-size: 1.2rem;
  }
}
