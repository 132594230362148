@import url('../../assets/css/globalStyles.css');

#h1{
    font-family: Alika;
    color: black;
    margin-top: 8%;
}
  
.collapsible {
    background-color: #eee;
    color: rgb(0, 0, 0);
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    border-radius: 5px;
    font-size: 25px;
    margin: 10px 0;
}

.collapsible h2{
   line-height: 45px;
    font-family: Roboto !important;
    color: black !important;
}

.active, .collapsible:hover {
    background-color: #a5a5a5;
}

.content {
    padding: 20px;
    display: none;
    overflow: hidden;
    font-size: 20px;
    background-color: #a5a5a5;
    border-radius: 5px;
    font-family: Roboto;
    color: black;
}

.showContent{
    display: block;
}
.hideContent{
    display: none;
}

.fontAwesomeStylesFaq {
    font-size: 2rem !important;
    margin-left: 1rem;
    margin-right: 1rem;
}

.lastSection{
    margin-bottom: 10%;
}

.faqH2{
    margin: unset !important;
}

@media only screen and (max-width: 768px) {
   
  }