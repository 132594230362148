@import url('../../assets/css/globalStyles.css');

.fontAwesomeStylesContact {
  font-size: 1.4rem !important;
  margin-left: 1rem;
  margin-top: 2rem;
  margin-right: 1rem;
  color: rgb(0, 0, 0);
}

.aStyles {
  color: rgb(0, 0, 0) !important;
  position: relative;
  font-family: Roboto !important;
}
.aStyles .tooltiptext {
  visibility: hidden;
  width: 5rem;
  background-color: #757e74;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-family: Roboto;
  font-size: 1rem !important;
  /* Position the tooltip */
  position: absolute;
  font-weight: normal;
  top: 150%;
  left: 50%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.4s;
}

.aStyles:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.aStyles:hover {
  color: #757e74 !important;
  text-decoration: underline !important;
  font-weight: bold;
}

.submitBtn {
  background-color: #757e74 !important;
  box-shadow: 2px 2px 4px #222222;
  margin-bottom: 5% !important;
  width: 100px;
  height: 50px;
  font-size: 20px !important;
  font-weight: 900 !important;
  font-family: Roboto;
}

#h1 {
  font-family: Alika;
  color: black;
  margin-top: 8%;
}

.h4 {
  font-family: Roboto !important;
  color: black !important;
}
