@import url('./scrollbarStyles.css');

@font-face {
  font-family: Roundhand;
  src: url('../fonts/Roundhand-Bold.ttf');
}

@font-face {
  font-family: Alika;
  src: url('../fonts/alika-misely.ttf');
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/RobotoCondensed-Light.ttf');
}


/* @font-face {
  font-family: Roundhand;
  font-weight: bold;
  src: url('../fonts/Roundhand-Bold.ttf');
} */

body {
  /* background-image: url('../images/planksBg.jpg');
  /* background: linear-gradien * t(rgba(0, 0, 0, 0.5)), */
  /* url('../images/planksBg.jpg'); */
  /* background-repeat: repeat;
  background-size: 100vw; */
  background-color: #c2c2c2 !important;
}
.darker {
  background-color: rgba(5, 2, 2, 0.3);
}

h1 {
  font-family: Roundhand;
  font-size: 5rem !important;
  color: white;
  margin-bottom: 2rem !important;
}
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 4rem !important;
    margin-bottom: 0rem !important;
  }
} */
h2 {
  color: white;
  font-size: 2rem !important;
  margin-top: 2rem !important;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
h4 {
  color: white;
  font-size: 1.3rem !important;
  margin-top: 2rem !important;
  font-family: Georgia, 'Times New Roman', Times, serif;
}
 p {
  color: white;
  font-size: 17px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}


.contentWrapper {
  padding-top: 1rem;
  min-height: 91vh;
}

.lightBlackBg {
  background-color: #222222 !important;
}

.textStyles {
  color: #7e7b7b !important;
}

.linkStyles {
  position: relative;
  color: #7e7b7b !important;
}
.linkStyles .tooltiptext {
  visibility: hidden;
  width: 5rem;
  background-color: #909592;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-family: Georgia, 'Times New Roman', Times, serif;

  /* Position the tooltip */
  position: absolute;
  top: 150%;
  left: 50%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.4s;
}

.linkStyles:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.linkStyles:hover {
  color: #909592 !important;
  text-decoration: none !important;
}
