.footerContainer {
  width: 100%;
  text-align: center;
  padding: 1%;
  font-size: 20px;
  background-color: var(--footer);
  height: 9vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6eeea !important;
}

.footerText {
  font-size: 1.5rem;
  font-family: Roboto;
}

.fontAwesomeFooterStyles {
  font-size: 1.6rem !important;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0.2rem;
}

.footerLinks{
  text-decoration: none !important;
  color: black;

}

.footerLinks:hover{
  color: rgb(255, 255, 255);
}

#linksContainer{
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: left;
  font-family: Roboto;
}