#deleteText{
    font-family: Alika;
    font-size: 50px !important;
    color: black;
    margin-top: 10% !important;
    margin-bottom: 5% !important;
}

.delete{
    font-family: Roboto !important;
    color: black !important;
  }