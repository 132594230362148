@import url('../../assets/css/globalStyles.css');

@media only screen and (max-width: 700px) {
  .navbar-nav {
    margin-left: 0.5rem;
    margin-top: 1rem;
  }
  .headerNews {
    margin-top: 1rem;
  }
  .linkStyles .fontAwesomeStyles {
    font-size: 3rem !important;
    margin-left: 1.5rem;
  }
}
.headerStyles {
  border-bottom: 1px #222222 solid !important;
  box-shadow: 0 2px 5px #222222 !important;
}

.navBrand {
  list-style-type: none !important;
}
.navbar-toggler {
  border: 2px solid black !important;
}

.fontAwesomeStyles {
  font-size: 2.3rem !important;
  margin-left: 1rem;
  color: black !important;
}

@media only screen and (max-width: 768px) {
  .userIcon {
    margin-left: 0.8rem !important;
    width: 2rem;
  }
}

.iconImg {
  width: 6rem;
  height: auto;
  margin-right: 10px;
  color: black;
}

.headerItem {
  margin: 1rem;
  font-size: 1.3rem;
  text-decoration: none;
  font-family: Alika;
  color: black !important;
}

.lightBlackBg {
  background-color: #e6eeea !important;
}

@media only screen and (max-width: 991px) {
  .logout {
    font-size: 16px;
  }
}
