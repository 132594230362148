.tableImage {
  width: 4rem;
}

#editH1{
  font-family: Alika !important;
  color: black !important;
  margin-top: 5% !important;
}

.borderColor {
  border-color: var(--army-green) !important;
}

.deleteBtn {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}
.tableStyles {
  text-align: center;
  vertical-align: middle !important;
}

.deleteBtnOnMobile {
  display: none !important;
}

.editWorksCheckbox {
  position: unset !important;
  margin: unset !important;
  width: 1.5rem;
  height: 1.5rem;
}
.editWorksCheckbox:hover {
  cursor: pointer;
}
.saveHighlightedProducts {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3rem;
  animation: fadein 0.5s;
}
@keyframes fadein {
  from {
    bottom: -6%;
  }
  to {
    bottom: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    bottom: -6%;
  }
  to {
    bottom: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    bottom: -6%;
  }
  to {
    bottom: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    bottom: -6%;
  }
  to {
    bottom: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    bottom: -6%;
  }
  to {
    bottom: 0;
  }
}
/* Mobile */
@media only screen and (max-width: 768px) {
  .hideWorksOnMobile {
    display: none;
  }
  .deleteBtnOnMobile {
    display: unset !important;
    margin-top: 3%;
  }
}
