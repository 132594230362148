.loginWrapper {
  width: 50%;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .loginWrapper {
    width: 90%;
  }
}
.loginForm {
  text-align: center;
}
.fontAwesomeStylesLogin {
  font-size: 1.2rem !important;
  color: rgb(0, 0, 0);
}

.iconStyle {
  background-color: #757e74 !important;
  border: 1px #757e74 solid !important;
  border-radius: 3px !important;
}
.inputStyle {
  border: 1px #757e74 solid !important;
  border-radius: 6px !important;
}

#loginH2{
  font-family: Alika;
  color: black;
  margin-top: 20% !important;
  margin-bottom: 5% !important;
  font-size: 50px !important;
}

