@import url('../../assets/css/globalStyles.css');

.pagBtn {
  height: 2.5rem;
  margin: 0.15rem;
  background-color: #757e74 !important;
  color: rgb(202, 199, 199) !important;
  border: none !important;
  border-radius: 0.4rem !important;
  box-shadow: 2px 2px 4px #222222;
}
.activeBtn {
  font-weight: bold !important;
  font-size: 1.2rem !important;
  color: white !important;
}
.cardHeader {
  background-color: #5c695b !important;
  height: 2.7rem;
  font-family: Roboto;
  font-weight: 900;
}
.cardWork {
  height: max-content;
  transition: transform 0.2s;
  border: none !important;
  box-shadow: 2px 2px 8px #222222;
}
.cardWork:hover,
.cardWork:active {
  transform: scale(1.8);
  z-index: 1;
}
.fontAwesomeArrows {
  font-size: 1.4rem !important;
  margin-top: 0.2rem;
}

#myWorksH1 {
  font-family: Alika;
  color: black;
  margin-top: 8%;
}

#H4 {
  font-family: Roboto;
  color: black;
}

@media only screen and (max-width: 768px) {
  .cardWork:hover,
  .cardWork:active {
  transform: unset;
  }
}
