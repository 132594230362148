.slidecontainer {
  width: 50%;
  margin: 2%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #5c695b;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #757e74;
  cursor: pointer;
}
#logo {
  border-radius: 50%;
  width: auto;
  height: 130%;
}
#announcment {
  text-align: center;
  background-color: #757e74;
}

.headlines {
  margin-top: 3rem !important;
  font-family: Alika;
  color: black;
}
.price {
  margin-bottom: 2rem;
}
.rangeInput {
  width: 8% !important;
  height: 40% !important;
  display: inline !important;
}
@media only screen and (max-width: 600px) {
  .rangeInput {
    width: 20% !important;
  }
}

#myWorksH1{
  font-family: Alika;
  color: black;
  margin-top: 8%;
}

.beregnText{
  font-family: Roboto;
  color: black;
}

#beregnSection{
  background-color: rgb(243, 243, 243);
  padding: 10px 50px;
  border-radius: 20px;
  margin: 5% 0%;
}

#lastPrice{
  font-size: 3rem !important;
  font-weight: 900
}

