#textContainer{
    background-color: #eee;
    border-radius: 10px;
    padding: 5%;
    margin-bottom: 5%; 
    margin-top: 5%;
    text-align: justify;
    word-wrap: break-word;
}

.otherH1{
    font-weight: 900 !important;
    text-align: left !important;
    margin-top: unset !important;
    font-family: Alika;
    color: black;
}

.h2{
    font-weight: 600 !important;
    margin-top: 5%;
}

.pTag{
    font-family: Roboto;
    color: black;
}

@media only screen and (max-width: 768px) {
    .otherH1{
        font-size: 2.5rem !important;
    }

  }
